<template>
  <b-modal ref="modal" title="Attach .tvsbkp and .tvsbkp.cert" hide-footer>
    <b-alert show variant="info">
      To continue with this order, you have to upload .tvsbk and .tvsbkp.cert
      please attach those files.
    </b-alert>
    <b-form-file @change="checkFiles" multiple/>
    <b-alert :show="invalid" variant="danger">
      It seems required files are not complete or those are invalid files, you can continue sending your order
      however you have to upload files after.
      <br>
      <b-button variant="dark" @click="next()">Continue without extra files</b-button>
    </b-alert>
  </b-modal>
</template>

<script>
export default {
  name: "AttachExtraFiles",
  data: () => ({
    invalid: false,
    files_requred: {
      '.tvsbkp': false,
      '.tvsbkp.cert': false
    }
  }),
  methods: {
    show() {
      this.$refs.modal.show()
    },
    checkFiles(e) {
      const files = Array.from(e.target.files);
      const keys = Object.keys(this.files_requred);
      files.forEach(file => {
        keys.forEach(key => {
          if (file.name.includes(key)) {
            this.files_requred[key] = true;
          }
        })
      })
      this.invalid = keys.every(el => !this.files_requred[el]);
      if (!this.invalid) {
        this.$refs.modal.hide();
        this.$swal({
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        }).then(() => {
          this.$emit('complete', files)
        })
      }
    },
    next() {
      this.$emit('complete')
      this.$refs.modal.hide();
    }
  }
}
</script>

<style scoped>

</style>
