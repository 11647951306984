<template>
  <b-card :body-class="bodyClass" class="no-shadow border-bottom">
    <h4 class="title">
      <i class="material-icons">description</i>
      {{ item.title }}
    </h4>
    <small>{{ item.description }}</small>
    <div class="text-right">
      <b-button variant="outline-ligth" @click="expand = !expand">{{expand? 'Hide' :'See'}} options</b-button>
    </div>
    <b-collapse :visible="expand">
      <b-row>
        <template v-for="def of definitions">
          <b-col lg="6" v-if="def.values.length">
            <!--              <h2 class="def-title">{{ def.title }}</h2>-->
            <b-list-group>
              <b-list-group-item v-for="option of def.values" class="d-flex justify-content-between"
                                 v-tippy="{ placement : 'top',  arrow: true, onShow: () => option? option.description !== null : false }"
                                 :content="option.description">
                <span>{{ option.name }}</span>
                <span :class="{'check': option.value === true}">{{
                    (option.value === true ? '✔' : option.value)
                  }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </template>
      </b-row>
    </b-collapse>
  </b-card>
</template>

<script>
import {FILE_OPTIONS_DEFINITION} from "@/constants";

export default {
  name: "TunedFileView",
  props: {
    item: Object,
    bodyClass: {
      type: String,
      default: 'p-1'
    }
  },
  data: () => ({
    definitions: FILE_OPTIONS_DEFINITION(),
    expand: false
  }),
  methods: {
    setOptions() {
      this.definitions.forEach(item => {
        item.values = this.item.visual_options.filter(option => {
          return option.location === item.field
        })
      })
    },
  },
  mounted() {
    this.setOptions()
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 19px;
  color: #505050;
  font-weight: 700;
  line-height: 19px;
  font-style: italic;
  text-transform: uppercase;
}

.list-group-item {
  border-radius: 0;
  padding: 0.3rem 1.1rem;
}

.def-title {
  letter-spacing: 0.3vw;
  font-weight: 300;
  color: #d14343;
  font-size: 14px;
  -webkit-font-smoothing: inherit;
}

.check {
  text-align: center;
  width: 22px;
  height: 22px;
  background: #ececec;
  border-radius: 50%;
  color: #1ADBA2;
}
</style>
