import Vue from 'vue';
import {formatDate} from '@/helpers/formatDate';
import {getDataStorage} from '@/helpers/localStorage';
import {get, post} from '@/services/api';
import {couponValidate, createOrder, getProfile} from '@/services/endpoint.json';
import {decrypt, encrypt} from '@/helpers/encrypt';
import {generateRandomInteger} from '@/helpers/random';
import {serialize, serializeToDataForm} from '@/utils';
import {mapGetters, mapMutations, mapState} from 'vuex';
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import TunedFileView from "@/components/SoftwareOrders/TunedFileView";
import AttachExtraFiles from "@/views/dashboard/checkout/AttachExtraFiles";
import moment from 'moment';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'CheckOut',
  components: {
    CreditsView,
    CurrencyPriceView,
    TunedFileView,
    AttachExtraFiles
  },
  mixins: [show_alert_mixin],
  data: () => ({
    dateNow: new Date(),
    name: '',
    lastName: '',
    country: '',
    email: '',
    phone: '',
    files: null,
    cart: null,
    coupon: '',
    isvalidCoupon: false,
    successCoupon: false,
    errorCoupon: false,
    originalPrice: 0,
    lastOrder: null,
    autoCupon: false,
    now: moment(),
    hardware: [],
    sofware: [],
    dsgOptions: [],
    preSelectedDsgOptions: [],
    hardwareMandatory: [],
    sofwareMandatory: [],
    showEcuInformation: false,
    oldCoupon: null,
    file_modal_complete: false,
    extra_files: [],
    previousOrderFound: false,
    upgrade_fee: 0,
    disable_submit: true
  }),
  computed: {
    ...mapGetters('geo', ['currencyToUser']),
    ...mapState('buySale', ['information']),
    ...mapState('orderSoftware', {
      vehicle: state => state.cart.vehicleId,
      tuned_files: state => state.cart.tuned_files,
      combos: state => state.combos,
      VIN: state => state.cart.vin,
      stage: state => state.params.id
    })
  },
  created() {
    this.dateNow = formatDate(this.dateNow);
    this.getProfile();
    this.getStorage();
    this.getDataFromCart()
  },
  mounted() {
    window.onbeforeunload = function () {
      return 'This action will erase your order and will make you start over.'
    }
    if (!this.$store.state.orderSoftware.cart.vin) {
      this.$router.push({name: 'order-software'}).catch()
    } else {
      this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        const element = document.getElementById(`${collapseId}-icon`)
        if (element) {
          element.classList.remove('fa-plus')
          if (isJustShown) {
            element.classList.remove('fa-plus')
            element.classList.add("fa-window-minimize");
          } else {
            element.classList.remove('fa-window-minimize')
            element.classList.add("fa-plus");
          }
        }
      })
    }
  },
  destroyed() {
    window.onbeforeunload = null;
    localStorage.removeItem('cart')
  },
  methods: {
    ...mapMutations(['loaderManager', 'userNameManager']),
    async getProfile() {
      const userId = getDataStorage('admin-id');
      let {data} = await get(getProfile, userId, true);
      if (Number(data.statusCode) === Number(200)) {
        this.name = data.message.name;
        this.lastName = data.message.lastName;
        this.country = data.message.country;
        this.email = data.message.email;
        this.phone = data.message.phone;
      }
    },
    getStorage() {
      this.cart = JSON.parse(localStorage.getItem('cart'));
      this.cart.description.subtotal = this.cart.description.price;
      this.originalPrice = this.cart.description.price;
      this.files = this.$store.state.orderSoftware.files;
      this.findPreorder();
    },
    async request() {
      if (this.tuned_files && this.tuned_files.length) {
        const exist = this.tuned_files.some(el => el.is_hpp);
        if (exist && !this.file_modal_complete) {
          this.$refs.attacher.show()
          return
        }
      }
      this.sockets.unsubscribe(`couponUpdated:${this.coupon}`)
      this.disable_submit = true;
      this.loaderManager(true);
      let userId = localStorage.getItem('admin-id');
      userId = decrypt(userId);
      const dataToSend = serializeToDataForm({
        orderId: userId + generateRandomInteger(8),
        type: this.$store.state.orderSoftware.cart.type,
        stage: this.stage,
        dealerId: userId,
        vin: this.VIN,
        vehicle: JSON.stringify({...this.$store.state.orderSoftware.cart.vehicleId, auto_found: true}),
        urgency: this.cart.urgency,
        description: JSON.stringify(this.cart),
        price: this.cart.description.price,
        subtotal: this.cart.description.subtotal,
        files: [...this.$store.state.orderSoftware.files, ...this.extra_files],
        tuned_files: this.tuned_files ? JSON.stringify(this.tuned_files) : null,
        currencyDetails: {
          country_id: this.$store.state.geo.userCountry.id,
          currency_code: this.$store.state.geo.userCurrency.code,
          original_price: this.cart.description.original_price,
          locale: this.$store.state.geo.userCurrency.locale,
          subtotal: (this.cart.description.price > 0 && this.cart.description.price !== 9999) ?
            (this.cart.description.subtotal - (this.cart.description.coupon ? this.cart.description.coupon.discount : 0)) :
            (this.cart.description.price === 9999) ? 9999 : 0
        },
      });
      post(createOrder, dataToSend, true).then(async ({data}) => {
        this.loaderManager(false);
        if (data) {
          await this.$store.dispatch('counterInformation/loadCounterData');
          let textMessage = 'Order placed successfully'
          if (Number(this.information.totalOrderSoftwareToPay) + Number(this.cart.description.price) > Number(this.information.balance)) {
            textMessage = `Order placed successfully.
            <br><br>
            <span class="text-danger">Insufficient funds, you don't have enough credits to complete this order.</span>`
          }
          this.clearStorageCar()
          await this.$swal({
            html: textMessage,
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1500,
          });
          await this.$router.push({name: 'order-detail', params: {id: encrypt(JSON.stringify({id: data.order.id}))}});
          location.reload()
        } else {
          this.loaderManager(false);
          await this.showAlertNotification('The process has ended unexpectedly. Please check that your software order has been created correctly');
        }
      }).catch(async (err) => {
        this.clearStorageCar()
        location.reload()
        console.log(err)
        this.loaderManager(false);
        await this.showAlertNotification('something went wrong, please try again later');
      })
    },
    clearStorageCar(){
      localStorage.removeItem('cart');
      localStorage.removeItem('admin-software');
      localStorage.removeItem('admin-dsg');
      this.$store.commit('orderSoftware/clear');
      this.$store.commit('orderSoftware/resetFullOrderSoftwareState');
      this.$destroy()
    },
    async applyCoupon() {
      this.resetPrices()
      if (this.coupon !== '' && (this.oldCoupon !== this.coupon)) {
        this.sockets.unsubscribe(`couponUpdated:${this.oldCoupon}`)
        this.oldCoupon = this.coupon
        const params = serialize({
          code: this.coupon,
          stage: this.$store.state.orderSoftware.params.id,
          type: this.$store.state.orderSoftware.params.type,
          dsg_family: this.$store.state.orderSoftware.cart.vehicleId.dsg_tvs,
          vehicle: {
            brand: this.$store.state.orderSoftware.cart.vehicleId.brand,
            model: this.$store.state.orderSoftware.cart.vehicleId.model,
            engine: this.$store.state.orderSoftware.cart.vehicleId.engine,
            generation: this.$store.state.orderSoftware.cart.vehicleId.generation,
            auto_found: true
          },
          is_upgrade: this.lastOrder !== null,
        }, '?');
        let {data} = await get(couponValidate, params, true);
        if (data.success) {
          this.isvalidCoupon = true;
          this.successCoupon = true;
          this.errorCoupon = false;
          this.cart.description.coupon = {
            id: data.id,
            discount: Number(this.cart.description.price) * data.percentage,
            percentage: Number(data.percentage),
            only_on_upgrade_fee: data.only_on_upgrade_fee,
            index: data.index,
          };
          if (data.only_on_upgrade_fee) {
            this.cart.description.coupon.discount = this.upgrade_fee.amount * data.percentage
            this.upgrade_fee.amount -= this.upgrade_fee.amount * data.percentage;
          } else {
            this.cart.description.price = this.cart.description.price - this.cart.description.coupon.discount;
          }
          this.sockets.subscribe(`couponUpdated:${this.coupon}`, () => {
            this.oldCoupon = null;
            this.applyCoupon();
          })
        } else {
          this.successCoupon = false;
          this.errorCoupon = true;
          this.resetCoupon()
        }
      } else if (this.coupon === '') {
        this.resetCoupon()
      }
    },
    resetPrices() {
      this.cart.description.price = this.cart.description.subtotal;
      this.cart.description.coupon = null;
    },
    resetCoupon() {
      this.resetPrices()
      this.sockets.unsubscribe(`couponUpdated:${this.oldCoupon}`)
      if (this.lastOrder) {
        this.applyAutoCoupon();
      }
      this.oldCoupon = this.coupon;
    },
    validateInput(event) {
      if (event.length === 0) {
        this.resetCoupon()
        this.successCoupon = false;
        this.errorCoupon = false;
      }
      this.coupon = event.toUpperCase()
    },
    findPreorder() {
      this.disable_submit = Number(this.cart.description.price) > 0
      if (this.cart.description.price) {
        post('order-software/find-history-to-upgrade', {vin: this.$store.state.orderSoftware.cart.vin}, true)
          .then(async ({data}) => {
            if (data.success) {
              this.lastOrder = {
                ...data.order,
                createdAt: moment(data.order.createdAt),
                description: JSON.parse(data.order.description),
              }
              if (!this.lastOrder) {
                await this.findPreviousPlatformOrder()
              }
              if (this.lastOrder) {
                await this.applyAutoCoupon()
              }
            }
            this.disable_submit = false
          });
      }
    },
    findPreviousPlatformOrder() {
      post(`previous-orders/find-last`,
        {vin: this.$store.state.orderSoftware.cart.vin}, true)
        .then(({data}) => {
          if (data) {
            this.previousOrderFound = data.length > 0;
          }
        });
    },
    async applyAutoCoupon() {
      const {data} = await get(`common/price-policy?country_id=${this.$store.state.geo.userCountry.id}&dealer_id=${this.$store.getters.getUserId}`);
      const dif = moment.duration(this.now.diff(this.lastOrder.createdAt));
      let priceOrder = this.cart.description.price;
      let items = this.lastOrder.description.item;
      if (this.lastOrder.price === 0 || items.find(el => el.id.toString().includes('original_file'))) {
        this.lastOrder = null;
        return
      }

      const proceed = this.lastOrder.type === this.cart.type || this.cart.type.includes('+');
      if (proceed) {
        if (dif.asYears() > 2.01 && dif.asYears() < 3) {
          this.successCoupon = true
          this.cart.description.coupon = {
            id: `AFTER 2 YEAR. ${data.data.discount_after_a_year}`,
            discount: Number(this.cart.description.price) * data.data.discount_after_a_year,
            percentage: data.data.discount_after_a_year
          };
          this.lastOrder = null;
          this.cart.description.price = this.cart.description.price - this.cart.description.coupon.discount;
        } else if (dif.asYears() < 2.01) {
          let lastPrice = this.lastOrder.price;
          let diff_new_pre_price = (priceOrder - lastPrice);
          if (diff_new_pre_price < data.data.minimum_price) {
            this.upgrade_fee = data.data.minimum_price - (diff_new_pre_price > 0 ? diff_new_pre_price : 0)
          }
          this.cart.description.to_refund = {
            order_id: this.lastOrder.id,
            upgrade_fee: this.upgrade_fee,
            amount: lastPrice
          };
        }
      } else {
        this.lastOrder = null;
      }
    },
    getDataFromCart() {
      this.sofware = this.cart.item.filter(x => x.location.toString() === 'sofware'); // ECU SOFTWARE SELECTABLE OPTIONS
      this.sofwareMandatory = this.cart.item.filter(x => x.location.toString() === 'sofwareMandatory'); // ECU SOFTWARE FEATURES
      this.hardware = this.cart.item.filter(x => x.location.toString() === 'hardware'); // ECU HARDWARE ADDITIONAL MODIFICATIONS
      this.hardwareMandatory = this.cart.item.filter(x => x.location.toString() === 'hardwareMandatory'); // ECU HARDWARE REQUIRED MODIFICATIONS

      this.preSelectedDsgOptions = this.cart.item.filter(x => x.location.toString() === 'preSelectedDsgOptions'); // DSG SOFTWARE FEATURES
      this.dsgOptions = this.cart.item.filter(x => x.location.toString() === 'dsgOptions');   // DSG SOFTWARE SELECTABLE OPTIONS
      this.dsgHardwareXoptions = this.cart.item.filter(x => x.location.toString() === 'dsgHardwareXoptions'); // DSG HARDWARE REQUIRED MODIFICATIONS
      this.dsgHardwareOptions = this.cart.item.filter(x => x.location.toString() === 'dsgHardwareOptions'); // DSG HARDWARE FEATURES
      this.validateEcuInformation()
    },
    validateEcuInformation() {
      this.showEcuInformation = (this.cart.description.client.tune !== null && this.cart.description.client.tune !== '' ||
        this.cart.description.client.power !== null && this.cart.description.client.power !== '' ||
        this.cart.description.client.turbo !== null && this.cart.description.client.turbo !== '' ||
        this.cart.description.client.add !== null && this.cart.description.client.add !== ''
      )
    },
    completeAttachModal(data) {
      if (data) {
        this.extra_files = data;
      }
      this.file_modal_complete = true;
    },
  },
});

